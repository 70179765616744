import Head from 'next/head';
import { FC } from 'react';

interface SEOProps {
  title: string;
  description: string;
  sitename?: string;
  asPath?: string;
  imageUrl?: string;
  robots?: string;
}

const SEO: FC<SEOProps> = ({
  title,
  description,
  sitename = 'BuyWisely',
  asPath = '',
  imageUrl = '/assets/images/landing/preview.png',
  robots,
}) => {
  return (
    <Head>
      <title>{`${title} | ${sitename}`}</title>
      <meta name="description" content={description} />
      <meta property="og:url" content={`${process.env.BASE_URL}${asPath}`} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={`${title} | ${sitename}`} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />

      <meta name="twitter:title" content={`${title} | ${sitename}`} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
      {robots && <meta name="robots" content={robots} />}
    </Head>
  );
};
export default SEO;
